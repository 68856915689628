import React from "react"
import { Link } from "gatsby"
import { Container, Col, Row } from "styled-bootstrap-grid"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"

const JobDetails = () => (
  <Layout>
    <SEO title="Job Details" />
    {/* job description section */}
    <Container fluid>
      <Container>
        <h1>SENIOR Full-Stack Developer</h1>
        <Row>
          <Col col="12" md="9">
            <p>
              Tunisia, Tunis • Full Time . Monthly salary: 1000TND - 1500TND
            </p>
            <p>Roles: Front-End Development, Back-End Development</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="">
              <button>EASY APPLY.</button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col col="12" md="9">
            <p>
              Are you interested in building products used by more than a
              billion people? Do you like shipping code at a rapid pace? We are
              seeking an experienced Front End Engineer that is passionate about
              building mobile and desktop web applications. This position is
              full-time and is based in our La Marsa office.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Responsibilities</h2>
          </Col>
        </Row>
        <Row>
          <Col col="12" md="9">
            <ul>
              <li>
                Implement the features and user interfaces of Facebook products
                like News Feed
              </li>
              <li>
                Architect efficient and reusable front-end systems that drive
                complex web applications
              </li>
              <li>
                Collaborate with Product Designers, Product Managers, and
                Software Engineers to deliver compelling user-facing products
              </li>
              <li>Identify and resolve performance and scalability issues</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Minimum Qualification</h2>
          </Col>
        </Row>
        <Row>
          <Col col="12" md="9">
            <ul>
              <li>
                3+ years of JavaScript experience, including concepts like
                asynchronous programming, closures, types, and ES6. Experience
                with React is a bonus
              </li>
              <li>
                3+ years of HTML/CSS experience, including concepts like layout,
                specificity, cross browser compatibility, and accessibility
              </li>
              <li>
                3+ years experience with browser APIs and optimizing front end
                performance
              </li>
              <li>
                {" "}
                BS/MS in Computer Science or a related technical field preferred
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col col="12" md="4">
            <Link to="">
              <button>EASY APPLY.</button>
            </Link>
          </Col>
          <Col col="12" md="4">
            <Link to="">
              <button>E-mail your application</button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
    {/* similar positions section */}
    <Container>
      <Row>
        <Col>
          <h1>Similar positions</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>We are looking for those profiles !</p>
        </Col>
      </Row>
      <Row>
        <Col col="12" md="9">
          <p>Junior Front-end Developer</p>
          <p>Internship : UX/UI Designer</p>
          <p>Senior Back-end Developer</p>
          <p>Senior Native iOS Engineer</p>
          <Link to="">
            <button>EXPLORE ALL OPENINGS</button>
          </Link>
        </Col>
        <Col col="12" md="3">
          <h4>Can’t find what you are looking for ?</h4>
          <p>
            We empower you to work on things you are passionate about. Take your
            career to the next level and join the xTECH family. Send us your
            resume.
          </p>
          <Link to="">
            <button>Send your resume anyway</button>
          </Link>
        </Col>
      </Row>
    </Container>
    {/* social media section */}
    <Container>
      <Row>
        <Col col="12" md="3">
          <span>linkedIn</span>
        </Col>
        <Col col="6" md="3">
          <span>facebook</span>
        </Col>
        <Col col="6" md="3">
          <span>instagram</span>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default JobDetails
